/* eslint-disable */
export const alertMixin = {
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      alertMessage: "",
      alertVariant: "warning"
    };
  },
  methods: {
    showAlert(variant, msg) {
      this.alertMessage = msg;
      this.alertVariant = variant;
      this.dismissCountDown = this.dismissSecs;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    }
  }
};

export default alertMixin;
